import React from 'react'
// import { Redirect } from 'react-router-dom'
// import Box from '@mui/material/Box'
// import * as mo from 'moment'
import { LinearProgress, Paper, Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { ModalCust, TopBar, Snack } from './Lib'
// import { styleGuide } from './Style'
import __ from '../util'

class DashView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      loading: false,
      snack: undefined,
      wpInstance: 0,
      aiInstance: 0,
      aiImage: 0,
      aiImageStyle: 2,
      language: 0,
      wpTitle: '',
      wpMainKw: '',
      wpSecKw: ''
    }
    this.state = {
      ...this.reset
    }
    this.postArticle = this.postArticle.bind(this)
  }

  async postArticle () {
    this.setState({ loading: true })
    try {
      await this.cx.dash.postArticle({
        wpInstance: this.state.wpInstance,
        aiInstance: this.state.aiInstance,
        aiImage: this.state.aiImage,
        aiImageStyle: this.state.aiImageStyle,
        language: this.state.language,
        wpTitle: this.state.wpTitle,
        wpMainKw: this.state.wpMainKw,
        wpSecKw: this.state.wpSecKw
      })
      //TODO LIVE this.setState({...this.reset, snack: 'Generating article in background...'})
      this.setState({snack: 'Generating article in background...', loading: false})
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }

  async componentDidMount () {

    Object.assign(this, __.initView(this, 'dash'))

    /*await Promise.all([
     this.getMembers()
   ])*/
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.loading) {
      return <LinearProgress />
    } else {
      return <div>
        {this.state.snack && (
          <Snack
            msg={this.state.snack}
            onClose={() => this.setState({ snack: undefined })}
          />
        )}
        <TopBar title noDash />
        <Grid container spacing={0} justifyContent='center' style={{paddingTop: 20}}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
            <Typography variant='h2' align='center'>
              Welcome to CBAi
              <PostAddIcon />
            </Typography>
            <Paper
              // className={this.props.classes.paperStyle}
              elevation={10}
              style={{padding: 15}}
            >
              <Button
                variant='contained'
                color='primary'
                style={{marginRight: 15}}
                onClick={() => this.props.history.push('/csvgen')}
              >
                CSV Gen
              </Button>
              <Button 
                variant='contained'
                color='primary'
                style={{marginRight: 15}}
                onClick={() => this.props.history.push('/niche')}
              >
                Niche Gen
              </Button>
              <Button 
                variant='contained'
                color='primary'
                onClick={() => this.props.history.push('/topic')}
              >
                Topic Gen
              </Button>
              {/*
              <FormControl fullWidth>
                <InputLabel id="ai-instance-label">Ai Instance</InputLabel>
                <Select
                  labelId="ai-instance-label"
                  id="ai-instance"
                  value={this.state.aiInstance}
                  label="Ai Instance"
                  style={{marginBottom: 20}}
                  // onChange={handleChange}
                >
                  <MenuItem value={0}>OpenAi v3.5 Turbo</MenuItem>
                  <MenuItem value={1} disabled>OpenAi v4</MenuItem>
                  <MenuItem value={2} disabled>Tesla Ai</MenuItem>
                  <MenuItem value={3} disabled>Cyber Ai</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="ai-image-label">Ai Image</InputLabel>
                <Select
                  labelId="ai-image-label"
                  id="ai-image"
                  value={this.state.aiImage}
                  label="Ai Image"
                  style={{marginBottom: 20}}
                  // onChange={handleChange}
                >
                  <MenuItem value={0}>DALL·E</MenuItem>
                  <MenuItem value={1} disabled>Midjourney</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="ai-imageStyle-label">Ai Image Style</InputLabel>
                <Select
                  labelId="ai-imageStyle-label"
                  id="ai-imageStyle"
                  value={this.state.aiImageStyle}
                  label="Ai Image Style"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({aiImageStyle: evt.target.value})}
                >
                  <MenuItem value={0}>Impressionistic painting</MenuItem>
                  <MenuItem value={1}>Whimsical painting</MenuItem>
                  <MenuItem value={2}>Lifestyle photography</MenuItem>
                  <MenuItem value={3}>Portrait photography</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="ai-language-label">Language</InputLabel>
                <Select
                  labelId="ai-language-label"
                  id="ai-language"
                  value={this.state.language}
                  label="Language"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({language: evt.target.value})}
                >
                  <MenuItem value={0}>English</MenuItem>
                  <MenuItem value={1} disabled>German</MenuItem>
                  <MenuItem value={2} disabled>Spanish</MenuItem>
                  <MenuItem value={2} disabled>Arabic</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="wp-instance-label">WP Instance</InputLabel>
                <Select
                  labelId="wp-instance-label"
                  id="wp-instance"
                  value={this.state.wpInstance}
                  label="WP Instance"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({wpInstance: evt.target.value})}
                >
                  {__.getJsonSto('core').wpInstance.map((wp, i) => (
                    <MenuItem value={i}>
                      {wp}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              <TextField 
                fullWidth 
                id="wp-title" 
                label="WP Title"
                variant="outlined" 
                placeholder="Example: Lates iOS Updates - iPhone Guide"
                style={{marginBottom: 20}}
                value={this.state.wpTitle}
                onChange={evt => this.setState({wpTitle: evt.target.value})}
              />

              <TextField 
                fullWidth 
                id="wp-mainKw"
                label="WP Main Keyword"
                variant="outlined"
                placeholder="Example: Lockscreen"
                style={{marginBottom: 20}}
                value={this.state.wpMainKw}
                onChange={evt => this.setState({wpMainKw: evt.target.value})}
              />

              <TextField 
                fullWidth
                id="wp-secKws"
                label="WP Secondary Keywords"
                variant="outlined"
                placeholder="Example: iPhone, buy, ios"
                style={{marginBottom: 20}}
                value={this.state.wpSecKw}
                onChange={evt => this.setState({wpSecKw: evt.target.value})}
              />

              <Button
                fullWidth
                variant='contained'
                color='primary'
                // className={this.props.classes.loginButton}
                onClick={async event => this.postArticle(event)}
                disabled={this.state.wpTitle.length === 0 || this.state.wpMainKw.length === 0 || this.state.wpSecKw.length === 0}
              >
                Post to WP
              </Button>
              */}

            </Paper>
          </Grid>
        </Grid>
      </div>
    }
  }
}

export default withStyles({
    // colorFormat
  })(DashView)
